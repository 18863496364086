@tailwind base;
@tailwind components;
@tailwind utilities;

.background-color-base {
  background-color: #2c8cf4;
}

.background-color-base-hover {
  background-color: #2c8df4d6;
}

.background-color-base-paging {
  background-color: #2563eb;
}

.custom-triangle {
  display: inline-block;
  margin: 0px 6px;
  vertical-align: middle;
}

.custom-triangle-5 {
  width: 6px;
  height: 3px;

  border-bottom: solid 11px white;
  border-left: solid 11px transparent;
  border-right: solid 11px transparent;
}

.custom-tooltip {
  @apply invisible absolute;
}

.custom-has-tooltip:hover .custom-tooltip {
  @apply visible z-50;
}
