.swal2-title {
  padding: 16px !important;
  font-size: 23px !important;
  font-weight: 400 !important;
}
.swal2-container {
  z-index: 9999;
}

.react-datepicker-wrapper {
  width: 100%;
}

@media screen and (min-width: 1024px) {
  @keyframes bottomToTop {
    from {
      top: 0px;
    }
    to {
      top: 50%;
    }
  }
  .animated-image-slide {
    animation-name: bottomToTop;
    animation-duration: 2s;
  }

  @keyframes bottomToTopUtility {
    from {
      top: 0px;
    }
    to {
      top: 25%;
    }
  }
  .animated-image-slide-utility {
    animation-name: bottomToTopUtility;
    animation-duration: 2s;
  }
}
@media screen and (max-width: 1023px) {
  @keyframes bottomToTop {
    from {
      top: 0px;
    }
    to {
      top: 25%;
    }
  }
  .animated-image-slide {
    animation-name: bottomToTop;
    animation-duration: 2s;
  }
}

input[type="password"]::-ms-reveal,
input[type="password"]::-ms-clear {
  display: none;
}
